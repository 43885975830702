<template>
  <div :class="{fixed: showCategories}" class="tabs-component">
    <div class="categories" v-if="brands.length > 1">
      <button
          v-for="(category, index) in brands"
          :key="index"
          :class="{ active: selectedCategory === index }"
          @click="selectCategory(index)"
      >
        {{ category.name }}
      </button>
    </div>

    <div ref="scrollContainer" :class="{subcategories: true, fixed: !showCategories && isFixed}" v-if="activeCategory">
      <button
          :class="{ active: selectedSubCategory === -1 }"
          @click="selectAllSubcategories"
      >
        Все линейки <span v-if="totalItemsInCategory">{{ totalItemsInCategory }}</span>
      </button>

      <button
          v-for="(subcategory, subIndex) in activeCategory.productLine"
          :key="subIndex"
          :id="'item-' + subIndex"
          :class="{ active: selectedSubCategory === subIndex }"
          @click="selectSubcategory(subIndex)"
      >
        {{ subcategory.name }} <span v-if="subcategory.items.length > 0">{{ subcategory.items.length }}</span>
      </button>
    </div>
  </div>
</template>


<script setup lang="ts">
import {computed, defineEmits, defineProps, getCurrentInstance, onBeforeUnmount, onMounted, ref, toRefs} from 'vue';
import {Analytics} from "@/analytics/Analytics";

const emit = defineEmits(["tabSelected"]);
const props = defineProps<{
  brands
}>();
const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const {brands} = toRefs(props);

// const categories = ref([]);
const selectedCategory = ref(0);
const selectedSubCategory = ref(-1);

const scrollContainer = ref(null);
const previousScroll = ref(0);
const showCategories = ref(false);

const fetchCategories = () => {
  // categories.value.push({name: "Все линейки", itemsCount: productLines.value.reduce((s, i) => s+= i.itemsCount, 0)})
  // categories.value.push(productLines.value)

  selectedCategory.value = 0;
  selectedSubCategory.value = -1;
};

const selectCategory = (category: number) => {
  selectedCategory.value = category;
  selectAllSubcategories('auto');

  analytics.sendEvent('Tab',
  {
      'Name': activeCategory.value.name,
  });
};

const selectSubcategory = (subCategory: number, behavior = 'smooth') => {
  selectedSubCategory.value = subCategory;
  scrollToActiveItem(subCategory, behavior);

  analytics.sendEvent('Quick filter',
      {
        'Name': activeSubcategory.value.name,
      });

  emit("tabSelected", {
    category: activeCategory.value.name,
    subcategory: activeSubcategory.value.name
  })
};

const selectAllSubcategories = (behavior = 'smooth') => {
  selectedSubCategory.value = -1;
  scrollToActiveItem(-1, behavior);

  analytics.sendEvent('Quick filter',
      {
        'Name': 'Все линейки',
      });

  emit("tabSelected", {
    category: activeCategory.value.name,
    subcategory: 'Все линейки'
  });
}

const activeCategory = computed(() => {
  return brands.value[selectedCategory.value];
})

const activeSubcategory = computed(() => {
  return activeCategory.value.productLine[selectedSubCategory.value];
})

const totalItemsInCategory = computed(() => {
  return activeCategory.value.productLine.reduce((total, subcategory) => {
    return total + (subcategory.items ? subcategory.items.length : 0);
  }, 0);
});

const initPage = () => {
  const activeCat = parseInt(localStorage.getItem("cat") || "-1");
  const activeSub = parseInt(localStorage.getItem("sub") || "-1");

  if (activeSub == -1) {
    return selectAllSubcategories('auto');
  }

  selectCategory(activeCat);
  selectSubcategory(activeSub, 'auto');
}

const isFixed = ref(false);
const offsetTop = ref(0);

const epsilon = 40;

const handleScroll = () => {
  const currentScroll = window.scrollY;

  // Скрыть категории, если скроллим вниз или если почти на самом верху (с погрешностью)
  if ((currentScroll > previousScroll.value && currentScroll > offsetTop.value) || currentScroll <= epsilon) {
    showCategories.value = false;
  }

  // Показать категории, если скроллим вверх, и не на самом верху
  if (currentScroll < previousScroll.value && currentScroll > epsilon) {
    showCategories.value = true;
  }

  // Запомнить текущее положение скролла
  previousScroll.value = currentScroll;

  // Зафиксировать subcategories, если скролл проходит порог
  isFixed.value = currentScroll > offsetTop.value;
};

function scrollToActiveItem(itemId: number, behavior='smooth') {
  if (itemId == -1) {
    scrollContainer.value?.scrollTo({
      top: 0,
      left: 0,
      behavior: behavior == 'smooth' ? 'smooth' : 'auto',
    })
  }

  const activeElement = document.getElementById(`item-${itemId}`);
  if (activeElement) {
    activeElement.scrollIntoView({
      block: "center",
      behavior: behavior == 'smooth' ? 'smooth' : 'auto',
      inline: 'center',
    });
  }
}

onBeforeUnmount(() => {
  localStorage.setItem("cat", selectedCategory.value.toString());
  localStorage.setItem("sub", selectedSubCategory.value.toString());

  window.removeEventListener('scroll', handleScroll);
});

onMounted(() => {
  fetchCategories();

  initPage();
  setTimeout(() => initPage, 100);

  const subcategories = document.querySelector('.subcategories');
  offsetTop.value = subcategories?.offsetTop;
  window.addEventListener('scroll', handleScroll);
});
</script>


<style lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .tabs-component {
    background-color: themed("background-main");
  }

  .categories {
    background-color: themed("background-secondary");

    button {
      background-color: themed("background-secondary");
      color: themed("accent-gray");

      &.active {
        background-color: themed("background-main");
        border: 0.5px solid themed("accent-gray-temp");
        color: themed("text");
      }
    }
  }

  .subcategories {
    background-color: themed("background-main");

    button {
      background-color: themed("background-secondary");
      color: themed("text");

      &.active {
        background-color: themed("button");
        color: themed("background-main");

        span {
          color: themed("background-main");
          opacity: 72%;
        }
      }

      span {
        color: themed("accent-gray");
      }
    }
  }
}

.tabs-component {
  width: 100%;
  height: 26.5vw;
  margin-top: 0;
  padding-top: 5vw;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 99999;

  .categories {
    display: flex;
    justify-content: space-between;
    padding: 2.2px 2px;
    margin-left: 4.3vw;
    margin-right: 4.3vw;
    border-radius: 12px;
    position: relative;
    z-index: 10;
    flex-shrink: 0;

    button {
      border: none;
      padding: 7px 20px;
      border-radius: 10px;
      font-size: 17px;
      font-weight: 510;
      cursor: pointer;
      //transition: background-color 0.3s ease;
      user-select: none;
      -webkit-tap-highlight-color: transparent;

      flex-grow: 1;
      text-align: center;
    }
  }

  .subcategories {
    display: flex;
    z-index: 99999;

    //position: fixed;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 10px;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 4vw 4.3vw 4.4vw;
    transition: top 0.3s ease; /* плавный переход */

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }


    button {
      flex-shrink: 0;
      border: none;
      padding: 1.45vw 3.6vw;
      border-radius: 20px;
      font-size: 18px;
      cursor: pointer;
      //transition: background-color 0.3s ease;

      user-select: none;
      -webkit-tap-highlight-color: transparent;

      span {
        padding-left: 1.4vw;
      }
    }
  }
}

.fixed {
  position: fixed;
  margin-top: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999; /* чтобы элемент оставался поверх */
}
</style>
