<template>
  <router-link class="container" :to="{path: '/good/' + brand +'/' + productLine + '/' + id}">
    <div class="content">
      <div class="title">
        {{ title }}
      </div>
      <div class="description">
        {{ description }}
      </div>
    </div>
    <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="chevron">
        <path id="Icon" d="M12.6 12L8.70005 8.10005C8.51672 7.91672 8.42505 7.68338 8.42505 7.40005C8.42505 7.11672 8.51672 6.88338 8.70005 6.70005C8.88338 6.51672 9.11672 6.42505 9.40005 6.42505C9.68338 6.42505 9.91672 6.51672 10.1 6.70005L14.7 11.3C14.8 11.4 14.8709 11.5084 14.9125 11.625C14.9542 11.7417 14.975 11.8667 14.975 12C14.975 12.1334 14.9542 12.2584 14.9125 12.375C14.8709 12.4917 14.8 12.6 14.7 12.7L10.1 17.3C9.91672 17.4834 9.68338 17.575 9.40005 17.575C9.11672 17.575 8.88338 17.4834 8.70005 17.3C8.51672 17.1167 8.42505 16.8834 8.42505 16.6C8.42505 16.3167 8.51672 16.0834 8.70005 15.9L12.6 12Z"/>
      </g>
    </svg>

  </router-link>

</template>

<script setup lang="ts">
import {defineProps, toRefs} from "vue";

const props = defineProps<{
  id: string,
  brand: string,
  productLine: string,
  title: string,
  description: string,
}>();

const {title, id, description} = toRefs(props);
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes){
  .container:not(:last-child) {
    border-bottom: 0.5px solid themed("separator");
  }
  .description {
    color: themed("hint");
  }
  .title {
    color: themed("text");
  }
  .arrow {
    fill: themed("separator");
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  padding: 2.5vw 0;
  min-height: 0;
}
.content {
  font-family: SF Pro, sans-serif;
  padding: 0 0;
  flex: 1;
}
.title {
  font-weight: normal;
  font-size: 4.4vw;
  margin-bottom: 0.2vw;
  max-width: 80%;
}
.description {
  font-weight: normal;
  font-size: 4vw;
}
.arrow {
  position: relative;
  right: 1.3vw;
  width: 7vw;
  height: 7vw;
}
</style>