<template>
  <div :class="theme + '-theme container'">
    <div class="content" :style="'top: ' + viewportHeight * 0.4 + 'px'">
      <div class="title">Доступ ограничен</div>
      <div class="description">{{ descriptionResult }}</div>
    </div>
    <div class="background"></div>
  </div>
</template>

<script setup lang="ts">
import {defineProps, ref, toRefs} from "vue";
import {useWebAppTheme, useWebAppViewport} from "vue-tg";

const theme = useWebAppTheme().colorScheme;
const viewportHeight = useWebAppViewport().viewportStableHeight;
const descriptionDefault = "Обратитесь в @yscanchat, если считаете, что это ошибка";

const props = defineProps<{
  description: string
}>();
const { description } = toRefs(props);

const descriptionResult = ref("");
descriptionResult.value = description.value == "" ? descriptionDefault : description.value
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .background {
    background: themed("background-main");
  }
  .title {
    color: themed("text");

  }
  .description {
    color: themed("hint");
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.content {
  position: absolute;
  text-align: center;
  width: 80%
}
.title {
  font-weight: bold;
  font-size: 8vw;
  margin-bottom: 2.15vw;
}
.description {
  font-weight: normal;
  font-size: 5.3vw;
}
.background {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>