<template>
  <div :class="theme + '-theme'">
    <!-- Заголовок вкладок -->
    <TabHeaderComponent @tabSelected="selectTab" :brands="goods"/>

    <!-- Контент для каждой подкатегории -->
    <TabContentComponent
        v-for="(productLine, index) in productLines"
        :key="index"
        :brand="productLine.categoryName"
        :productLine="productLine.categoryName + '-' + productLine.name"
        :goods="productLine.items"
        :advertises="advertises"
        :active-tab="activeTab"
    />

    <div class="background"></div>
  </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, Ref, ref} from 'vue';
import TabHeaderComponent from "@/components/home/tab/TabHeaderComponent.vue";
import TabContentComponent from "@/components/home/tab/TabContentComponent.vue";
import {useWebAppTheme} from 'vue-tg';
import {AdvertiseObject} from "@/interfaces/advertises/AdvertiseInterface";

// Тема веб-приложения
const theme = useWebAppTheme().colorScheme;

// Референсы для хранения данных
const advertises: Ref<AdvertiseObject[]> = ref(getCurrentInstance()?.appContext.config.globalProperties.advertises);
const goods: Ref<[]> = ref(getCurrentInstance()?.appContext.config.globalProperties.goods);

// Массивы для хранения категорий, подкатегорий и активной вкладки
const brands: Ref<string[]> = ref([]);
const productLines = ref([]);
const activeTab: Ref<string> = ref("");
const prevTab = ref("");

// Заполняем массивы brands и productLines данными из goods
for (let i = 0; i < goods.value.length; i++) {
  const category = goods.value[i];
  brands.value.push(category.name);

  // Собираем товары для подкатегорий и создаем "Все линейки"
  const allItems = [];
  const subcategories = category.productLine.map(subcategory => {
    // Добавляем поле productLine в каждый товар подкатегории
    subcategory.items.forEach(item => {
      item.productLine = subcategory.name;
    });

    // Собираем все товары для подкатегории "Все линейки"
    allItems.push(...subcategory.items);

    return {
      categoryName: category.name,  // Имя категории
      name: subcategory.name,       // Имя подкатегории
      items: subcategory.items      // Товары с полем productLine
    };
  });

  // Добавляем подкатегорию "Все линейки"
  productLines.value.push({
    categoryName: category.name,
    name: 'Все линейки',
    items: allItems,
  });

  // Добавляем остальные подкатегории
  productLines.value.push(...subcategories);
}


// Обработчик для смены активной вкладки
const selectTab = (tab) => {
  prevTab.value = activeTab.value;
  activeTab.value = tab.category + '-' + tab.subcategory;
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .background {
    background: themed("background-secondary");
  }
}

.background {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
